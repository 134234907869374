import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import log from '@wix/wix-log';
import _ from 'lodash';
import he from 'he';
import { GeoData, web } from '@wix/wix-common-aspects';
import { WixDesignSystemProvider } from '@wix/design-system';
import { Service } from '@wix/ambassador-category-services/types';
import {
  WixNextApiRequest,
  WixNextApiResponse,
  IParams,
  SEOProps,
  PartnerProfileEnriched,
  HadronConfig,
  LocaleDataTranslations,
} from '@common/types';
import {
  PROFILE_NOT_FOUND,
  ENABLE_TRACEABILITY_OF_TRADERS,
} from '@utils/constants';
import createProvidersAPI from '@services/ProvidersAPI';
import createServicesAPI from '@services/ServicesAPI';
import createSeoAPI from '@services/ProfileSeoAPI';
import createLanguagesAPI from '@services/LanguagesAPI';
import createCurrencyAPI from '@services/CurrencyAPI';
import createPetriServiceAPI from '@services/PetriServiceAPI';
import createPartnerTraceableTraderAPI from '@services/PartnerTraceableTraderAPI';
import { Language } from '@common/types';
import { ProfileContextProvider } from '@components/Contexts/ProfileContext';
import { HeaderContextProvider } from '@components/Contexts/HeaderContext';
import { BiSessionContextProvider } from '@components/Contexts/BiSessionContext';
import Profile from '@components/Profile';
import NotFound from '@components/Profile/NotFound';
import { getCurrencyPreferences } from '../hire/[[...all]]';

const WixPartner: NextPage<{
  partnerProfile: PartnerProfileEnriched;
  services: Array<Service>;
  categoriesForHeader: Array<Service> | undefined;
  geo: GeoData;
  language: string;
  languages: Array<Language>;
  origin: string;
  entry: any;
  isMyProfile: boolean;
  enabled: boolean;
  isRenderedInModal: boolean;
  isMobileUserAgent: boolean;
  error: string;
  host: string;
  seo: SEOProps;
  userCurrency: string;
  currencyConversionRate: number;
  currencyPreferences: string;
  localeDataTranslations: LocaleDataTranslations;
  shouldDisplayTraceabilityData: boolean;
}> = ({
  partnerProfile,
  services,
  categoriesForHeader,
  geo,
  language,
  languages,
  origin,
  entry,
  isMyProfile,
  enabled,
  isRenderedInModal,
  isMobileUserAgent,
  host,
  error,
  seo: { title, metaTags, links, structureData, gtm },
  userCurrency,
  currencyConversionRate,
  currencyPreferences,
  localeDataTranslations,
  shouldDisplayTraceabilityData,
}) => {
  const isProd = process.env.NODE_ENV === 'production';

  const ProfileHeader = () => (
    <Head>
      <title>{title}</title>
      {_.map(links, (attr, index) =>
        attr.rel === 'canonical' ? null : (
          <link
            hrefLang={attr.hrefLang ? attr.hrefLang : 'x-default'}
            rel={attr.rel}
            href={attr.href}
            key={`lang-${index}`}
          />
        ),
      )}
      {_.map(metaTags, (attr, index) => (
        <meta {...attr} key={`meta-${index}`} />
      ))}
      <link
        rel="canonical"
        href={_.get(_.find(links, { rel: 'canonical' }), 'href')}
      ></link>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: structureData }}
      ></script>
      {isProd && <script dangerouslySetInnerHTML={{ __html: gtm }}></script>}
    </Head>
  );

  return (
    <>
      <ProfileHeader />
      <BiSessionContextProvider>
        <HeaderContextProvider
          services={categoriesForHeader || services}
          host={host}
          userCurrency={userCurrency}
        >
          <ProfileContextProvider
            partnerProfile={partnerProfile}
            services={services}
            geo={geo}
            language={language}
            languages={languages}
            origin={origin}
            host={host}
            entry={entry}
            isMyProfile={isMyProfile}
            enabled={enabled}
            isRenderedInModal={isRenderedInModal}
            isMobileUserAgent={isMobileUserAgent}
            userCurrency={userCurrency}
            currencyConversionRate={currencyConversionRate}
            preferredCurrency={currencyPreferences}
            localeDataTranslations={localeDataTranslations}
            shouldDisplayTraceabilityData={shouldDisplayTraceabilityData}
          >
            <WixDesignSystemProvider features={{ newColorsBranding: true }}>
              {error === PROFILE_NOT_FOUND ? <NotFound /> : <Profile />}
            </WixDesignSystemProvider>
          </ProfileContextProvider>
        </HeaderContextProvider>
      </BiSessionContextProvider>
    </>
  );
};

export async function getServerSideProps({
  req,
  res,
  query,
  params,
}: {
  req: WixNextApiRequest;
  res: WixNextApiResponse;
  query: IParams;
  params: any;
}) {
  const { slug } = params;
  const isRenderedInModal = query?.inModal === 'true';
  const host = `https://${req.get('host')}`;

  const servicesAPI = createServicesAPI(res.locals.appContext, req.aspects);
  const partnerTraceableTraderAPI = createPartnerTraceableTraderAPI(
    res.locals.appContext,
    req.aspects,
  );
  const petriServiceAPI = createPetriServiceAPI(
    res.locals.appContext,
    req.aspects,
  );
  const enableTraceabilityOfTraders = await petriServiceAPI.isExperimentEnabled(
    ENABLE_TRACEABILITY_OF_TRADERS,
  );

  const language = req.aspects?.get(web.Language) || 'en';
  const geo = req.aspects?.get(web.Geo);
  const url = req.aspects?.get(web.Url);
  const alpha2CountryCode = geo?.['2lettersCountryCode'];
  let shouldDisplayTraceabilityData = false;

  const currencyAPI = createCurrencyAPI(res.locals.appContext, req.aspects);
  const currencyPreferences = await getCurrencyPreferences({
    alpha2CountryCode,
    currencyAPI,
    req,
    res,
  });

  const { userCurrency, currencyConversionRate } =
    await currencyAPI.getCurrencyAndConversionRate({ currencyPreferences });

  const providersAPI = createProvidersAPI(res.locals.appContext, req.aspects);
  const languagesAPI = createLanguagesAPI(res.locals.appContext, req.aspects);

  try {
    let partnerProfile: any = await providersAPI.findPartnerProfileBySlug(
      he.decode(slug as string),
    );
    const [services, categoriesForHeader, traceabilityCountries] =
      await Promise.all([
        servicesAPI.getAllServicesWithHierarchy({
          withPathName: true,
          withTranslatedKey: true,
          language,
        }),
        servicesAPI.getCategoriesForHeader({
          withPathName: true,
          withTranslatedKey: true,
          language,
        }),
        partnerTraceableTraderAPI.getTraceabilityCountries(),
      ]);
    if (enableTraceabilityOfTraders && !partnerProfile.error) {
      if (_.includes(traceabilityCountries, alpha2CountryCode)) {
        if (partnerProfile.euValid !== true) {
          partnerProfile = { error: PROFILE_NOT_FOUND, state: undefined };
        } else {
          shouldDisplayTraceabilityData = true;
        }
      }
    }
    if (partnerProfile.error) {
      return {
        props: {
          partnerProfile: {},
          services,
          categoriesForHeader,
          error: partnerProfile.error,
          seo: {
            title: 'Profile Not Found - Wix Marketplace',
          },
          host,
        },
      };
    }

    const { entry, origin } = query;
    const currentPartnerProfile = await providersAPI.getCurrentPartnerProfile();
    const languages = await languagesAPI.getLanguages();

    const seoAPI = await createSeoAPI({
      partnerProfile,
      language,
      url,
    });
    const config = res.locals.appContext.config.load<HadronConfig>(
      'experts-marketplace-nextjs-template.json',
    );
    const staticLocaleUrl = await res.locals.hadron.staticUrl(
      'com.wixpress.locale-dataset-data',
      config.clientTopology.locale_dataset_data_static_url,
    );

    const localeDataTranslations = await currencyAPI.getLocaleDataTranslation(
      staticLocaleUrl,
      language,
    );

    const props = {
      slug,
      ...(partnerProfile && {
        partnerProfile: JSON.parse(JSON.stringify(partnerProfile)),
      }),
      enabled: partnerProfile?.state?.enabled || false,
      language,
      languages,
      ...(geo && { geo }),
      services,
      categoriesForHeader,
      ...(origin && { origin }),
      ...(entry && { entry }),
      isMyProfile: currentPartnerProfile?.slug === slug,
      isRenderedInModal,
      host,
      seo: {
        title: seoAPI.getPageTitle(),
        metaTags: seoAPI.getPageMetaTags(),
        links: seoAPI.getPageLinkTags(),
        structureData: JSON.parse(
          JSON.stringify(seoAPI.getProfileStructureData(partnerProfile)),
        ),
        gtm: seoAPI.getProfileGTM(),
      },
      userCurrency,
      currencyConversionRate,
      ...(currencyPreferences && { currencyPreferences }),
      localeDataTranslations,
      shouldDisplayTraceabilityData,
    };

    return {
      props,
    };
  } catch (e: any) {
    const { message, stack } = e;
    log('wix-partner')
      .withAspects(req.aspects)
      .error(`error w/ wix-partner`, { message, stack });
    return {
      props: { message, stack, partnerProfile: {}, seo: {} },
    };
  }
}

export default WixPartner;
