import React from 'react';
import { marketplaceHeaderClientGenericClick } from '@wix/bi-logger-marketplace/v2';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { useBi } from '../../Hooks/useBi';
import MarketplaceLogo from '../../Images/MarketplaceLogo';
import { LOGO_HEIGHT, LOGO_WIDTH } from '../utils/constants';
import s from './FormattedMarketplaceLogo.module.scss';

const FormattedMarketplaceLogo = ({
  isInternal,
  pageName,
}: {
  isInternal: boolean;
  pageName: string;
}) => {
  const logger = useBi();
  const { t } = useTranslation();

  return (
    <li className={`${s.cell} ${isInternal ? s.internal : ''}`}>
      <a
        href={'https://www.wix.com/marketplace'}
        aria-label={t('marketplace-header-logo-link-aria-label')}
        data-hook="logo-link"
        onClick={() => {
          logger.report(
            marketplaceHeaderClientGenericClick({
              clickType: 'wix marketplace',
              pageName,
            }),
          );
        }}
        style={{ height: `${LOGO_HEIGHT}px` }}
      >
        <MarketplaceLogo width={LOGO_WIDTH} height={LOGO_HEIGHT} />
      </a>
      {isInternal && <h5 className={s.internal}>&nbsp;/ INTERNAL</h5>}
    </li>
  );
};

export default FormattedMarketplaceLogo;
