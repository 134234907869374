import { useCallback } from 'react';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { ENABLE_MARKETPLACE_TEMPLATES_SPEC } from '@utils/constants';

export function useTemplateGalleryExperiment() {
  const { experiments } = useExperiments({ readOnly: true });

  const isTemplatesGalleryEnable = useCallback(() => {
    const enableMarketplaceTemplates = experiments.enabled(
      ENABLE_MARKETPLACE_TEMPLATES_SPEC,
    );
    return enableMarketplaceTemplates;
  }, [experiments]);

  return isTemplatesGalleryEnable;
}
