import React, { FC, useState, useEffect, useMemo } from 'react';
import {
  useTranslation,
  Trans,
  useHttpClient,
  useErrorMonitor,
} from '@wix/fe-essentials-standalone';
import { Box, TextButton, Text } from '@wix/design-system';
import { ChevronDownSmall, ChevronUpSmall } from '@wix/wix-ui-icons-common';
import {
  type TraceableTrader,
  TraderType,
} from '@wix/ambassador-partners-traceability-v1-traceable-trader/types';
import { useProfileContext } from '../../Contexts/ProfileContext';

type TraceableTraderDataType = {
  businessName?: string | null;
  businessType?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  businessAddress?: string | null;
};

const TraceableTraderData: FC = () => {
  const errorMonitor = useErrorMonitor();
  const httpClient = useHttpClient();
  const {
    shouldDisplayTraceabilityData,
    partnerProfile: { id: partnerProfileId },
  } = useProfileContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEuComplianceOpen, setIsEuComplianceOpen] = useState<boolean>(false);
  const [isTracabilityDetailsOpen, setIsTracabilityDetailsOpen] =
    useState<boolean>(false);
  const [traceableTrader, setTraceableTrader] = useState<
    TraceableTrader | undefined
  >(undefined);

  const traceableTraderData: TraceableTraderDataType | undefined =
    useMemo(() => {
      if (traceableTrader) {
        const data: TraceableTraderDataType = {};
        if (traceableTrader.traderType === TraderType.COMPANY) {
          data.businessName = traceableTrader.companyOptions?.businessName;
          data.businessType = t(
            `profile-page-traceability-eu-law-compliance-business-type-option-${traceableTrader.companyOptions?.businessType}`,
          );
        } else if (traceableTrader.traderType === TraderType.FREELANCER) {
          data.businessName = `${traceableTrader.freelancerOptions?.firstName} ${traceableTrader.freelancerOptions?.lastName}`;
        }
        data.phoneNumber = traceableTrader.businessPhone;
        data.email = traceableTrader.businessEmail;
        data.businessAddress = `${
          traceableTrader.businessAddress?.streetAddress?.formattedAddressLine
        }, ${traceableTrader.businessAddress?.city}${
          traceableTrader.businessAddress?.subdivision
            ? `, ${traceableTrader.businessAddress?.subdivision}`
            : ''
        }, ${traceableTrader.businessAddress?.postalCode}, ${
          traceableTrader.businessAddress?.country
        }`;
        return data;
      } else return undefined;
    }, [traceableTrader]);

  useEffect(() => {
    if (shouldDisplayTraceabilityData && isLoading) {
      (async () => {
        try {
          const { data } = await httpClient.get<any>(
            `/api/getTraceableTraderData?partnerProfileId=${partnerProfileId}`,
          );
          setTraceableTrader(data.traceableTrader);
        } catch (e) {
          errorMonitor.captureMessage(`Error @getTraceableTraderData`, {
            contexts: {
              data: {
                partnerProfileId,
                url: window.location.href,
              },
              error: { e },
            },
          });
        }
        setIsLoading(false);
      })();
    }
  }, [shouldDisplayTraceabilityData, isLoading]);

  return !isLoading && traceableTraderData ? (
    <Box
      dataHook="traceable-trader-data-section"
      display="block"
      margin="36px 0"
    >
      <TextButton
        dataHook="eu-law-compliance-link"
        size="tiny"
        weight="thin"
        skin="dark"
        suffixIcon={
          isEuComplianceOpen ? <ChevronUpSmall /> : <ChevronDownSmall />
        }
        onClick={() => {
          setIsEuComplianceOpen(!isEuComplianceOpen);
        }}
      >
        {t('profile-page-traceability-eu-law-compliance-toggle')}
      </TextButton>
      {isEuComplianceOpen ? (
        <Box
          dataHook="traceability-eu-law-compliance-details"
          display="block"
          padding="6px 0"
        >
          <Trans i18nKey="profile-page-traceability-eu-law-compliance-text">
            <Text size="tiny" weight="thin">
              This professional has represented.....
              <TextButton
                dataHook="more-info-link"
                size="tiny"
                weight="thin"
                skin="dark"
                underline="always"
                onClick={() => {
                  setIsTracabilityDetailsOpen(!isTracabilityDetailsOpen);
                }}
              >
                More info
              </TextButton>
            </Text>
          </Trans>
          {isTracabilityDetailsOpen ? (
            <Box
              dataHook="traceable-trader-data-details"
              direction="vertical"
              padding="6px 0"
            >
              <Text
                dataHook="traceable-trader-business-name"
                size="tiny"
                weight="thin"
                skin="disabled"
              >
                {t(
                  'profile-page-traceability-eu-law-compliance-business-name',
                  { businessName: traceableTraderData.businessName },
                )}
              </Text>
              {traceableTraderData.businessType ? (
                <Text
                  dataHook="traceable-trader-business-type"
                  size="tiny"
                  weight="thin"
                  skin="disabled"
                >
                  {t(
                    'profile-page-traceability-eu-law-compliance-business-type',
                    { businessType: traceableTraderData.businessType },
                  )}
                </Text>
              ) : null}
              <Text
                dataHook="traceable-trader-phone-number"
                size="tiny"
                weight="thin"
                skin="disabled"
              >
                {t('profile-page-traceability-eu-law-compliance-phone-number', {
                  phoneNumber: traceableTraderData.phoneNumber,
                })}
              </Text>
              <Text
                dataHook="traceable-trader-email"
                size="tiny"
                weight="thin"
                skin="disabled"
              >
                {t('profile-page-traceability-eu-law-compliance-email', {
                  email: traceableTraderData.email,
                })}
              </Text>
              <Text
                dataHook="traceable-trader-business-address"
                size="tiny"
                weight="thin"
                skin="disabled"
              >
                {t(
                  'profile-page-traceability-eu-law-compliance-business-address',
                  { businessAddress: traceableTraderData.businessAddress },
                )}
              </Text>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  ) : null;
};

export default TraceableTraderData;
