import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  useRef,
  HTMLAttributes,
} from 'react';
import _ from 'lodash';
import { animateScroll } from 'react-scroll';
import { useTranslation, useErrorMonitor } from '@wix/fe-essentials-standalone';
import {
  marketplaceBriefGeneralAction,
  partnersProfilePagePageLoaded,
  copyOfExploreResultsPageGenericClick,
} from '@wix/bi-logger-marketplace/v2';
import { Box, Notification } from '@wix/design-system';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntersect } from '@wix/experts-common';
import { useBi } from '@components/Hooks/useBi';
import useMobileMediaQuery from '@components/Hooks/useMobileMediaQuery';
import { useProfileContext } from '@components/Contexts/ProfileContext';
import { useHeaderContext } from '@components/Contexts/HeaderContext';
import { useBiSessionContext } from '@components/Contexts/BiSessionContext';
import Header from '@components/Header';
import CompactBrief from '@components/CompactBrief';
import ErrorFallback from '@components/ErrorFallback';
import NavBar from './NavBar';
import ContactPanel from './ContactPanel';
import MiniContactPanel from './MiniContactPanel';
import DividerWrapper from './DividerWrapper';
import About from './About';
import Services from './Services';
import FeaturedProjects from './FeaturedProjects';
import StartingRates from './StartingRates';
import ProviderLanguages from './ProviderLanguages';
import ProviderLocation from './ProviderLocation';
import ProviderSocialLinksMobile from './ProviderSocialLinksMobile';
import { Sections } from './NavBar/NavBar';
import ProjectsReviews from './ProjectsReviews';
import TraceableTraderData from './TraceableTraderData';
import s from './Profile.module.scss';

interface SectionWrapperProps extends HTMLAttributes<HTMLElement> {
  sectionId: string;
  children: any;
}

const SectionWrapper = React.forwardRef(
  (props: SectionWrapperProps, ref: any) => {
    const { sectionId, children } = props;
    return (
      <section
        id={sectionId}
        tabIndex={-1}
        ref={(el) => {
          if (ref?.current) {
            ref.current[sectionId] = el;
          }
        }}
        className={s.navigationSection}
        {...props}
      >
        {children}
      </section>
    );
  },
);

const Profile: FC = () => {
  const { t } = useTranslation();
  const logger = useBi();
  const { sessionId } = useBiSessionContext();
  const { userDetails } = useHeaderContext();
  const {
    partnerProfile,
    entry,
    origin,
    appStatus,
    enabled,
    isRenderedInModal,
    isMobileUserAgent,
  } = useProfileContext();
  const isMobileView = useMobileMediaQuery(isMobileUserAgent);
  const errorMonitor = useErrorMonitor();
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [ref, entryElement] = useIntersect({}) as any;
  const [showShadow, setShowShadow] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [briefTimedOutMessage, setBriefTimedOutMessage] = useState(false);

  const navigationRefs = useRef<any>({});

  useEffect(() => {
    if (entryElement.intersectionRatio < 1) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  }, [entryElement]);

  const handleHashChange = useCallback(() => {
    if (_.includes(window.location.hash, '#brief')) {
      setIsOpen(enabled);
    } else {
      setIsOpen(false);
    }
  }, [userDetails, enabled]);

  useEffect(() => {
    window.addEventListener('popstate', handleHashChange);
    if (_.includes(window.location.hash, '#brief') && enabled) {
      handleHashChange();
    }
    return () => {
      window.removeEventListener('popstate', handleHashChange);
    };
  }, [handleHashChange]);

  function closeModal(exitType?: string) {
    if (exitType !== 'submit') {
      logger.report(
        marketplaceBriefGeneralAction({
          briefType: 'compact',
          action_type: 'exit/cancel',
          sessionId,
          origin,
          entry,
        }),
      );
    }
    history.replaceState(history.state, '', '#');
    setIsOpen(false);
    setBriefTimedOutMessage(false);
  }

  useEffect(() => {
    try {
      if (location.hash) {
        const sectionId = location.hash.slice(1);
        if (document && document.getElementById(sectionId)) {
          const offset =
            document.getElementById(sectionId)!.getBoundingClientRect().top +
            105;
          animateScroll.scrollMore(offset, {
            duration: 1500,
            delay: 100,
            smooth: true,
          });
        }
      }
    } catch (err) {
      errorMonitor.captureException(err as Error);
    }
  }, [errorMonitor]);

  function onContactClicked() {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType: 'connect',
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data: '',
      }),
    );
    history.replaceState(history.state, '', '#brief');
    handleHashChange();
  }

  function headerOnClick() {
    logger.report(
      copyOfExploreResultsPageGenericClick({
        clickType: 'marketplace top button',
        sessionId,
        origin,
        entry,
        exploreVersion: 'V2',
        data: '',
      }),
    );
  }

  useEffect(() => {
    if (appStatus === 'initialized' && !isFirstLoad) {
      logger.report(
        partnersProfilePagePageLoaded({
          sessionId,
          origin: origin || document.referrer,
          entry,
          exploreVersion: 'V2',
          provider_id: partnerProfile.id,
        }),
      );
      setIsFirstLoad(true);
    }
  }, [appStatus, entry, isFirstLoad, origin, partnerProfile.id, sessionId]);

  if (isMobileView) {
    return (
      <Box display="block" dataHook="mobile-profile-page">
        {!isRenderedInModal ? (
          <Header
            onClick={headerOnClick}
            pageName="profile page"
            sessionId={sessionId}
          />
        ) : null}
        <Notification
          type="local"
          theme="success"
          show={showSuccessNotification}
          autoHideTimeout={8000}
          onClose={() => {
            setShowSuccessNotification(false);
          }}
          dataHook="brief-success-message"
        >
          <Notification.TextLabel>
            {t('brief-submit-success-message')}
          </Notification.TextLabel>
          <Notification.CloseButton />
        </Notification>
        <Notification
          type="local"
          theme="error"
          show={showErrorNotification}
          autoHideTimeout={8000}
          onClose={() => {
            setShowErrorNotification(false);
          }}
        >
          <Notification.TextLabel>
            {t('brief-submit-error-message')}
          </Notification.TextLabel>
          <Notification.CloseButton />
        </Notification>
        <ContactPanel isMobileView={true} onContactClicked={onContactClicked} />
        <div
          ref={ref}
          className={
            showShadow ? `${s.stickyHeader} ${s.showShadow}` : s.stickyHeader
          }
        >
          <MiniContactPanel onContactClicked={onContactClicked} />
          <NavBar isMobileView={true} navigationRefs={navigationRefs} />
        </div>
        <Box marginLeft="24px" marginRight="24px" direction="vertical">
          <SectionWrapper sectionId={Sections.ABOUT} ref={navigationRefs}>
            <About isMobileView={true} />
            <Box paddingTop="24px" direction="vertical">
              <ProviderLocation showTitle={true} marginTop="18px" isMobile />
              <Box height="10px"></Box>
              <ProviderLanguages isMobile />
            </Box>
            <Box paddingBottom="22px">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Services isMobile={isMobileView} />
              </ErrorBoundary>
            </Box>
            <Box>
              <ProviderSocialLinksMobile />
            </Box>
          </SectionWrapper>
          <SectionWrapper
            sectionId={Sections.FEATURED_PROJECTS}
            ref={navigationRefs}
          >
            <DividerWrapper isMobileView={true}>
              <FeaturedProjects isMobileView={true} />
            </DividerWrapper>
          </SectionWrapper>
          <SectionWrapper
            sectionId={Sections.SERVICES_AND_RATES}
            ref={navigationRefs}
          >
            <DividerWrapper
              paddingTop="36px"
              paddingBottom="20px"
              isMobileView={true}
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StartingRates isMobileView={true} />
              </ErrorBoundary>
            </DividerWrapper>
          </SectionWrapper>
          <SectionWrapper sectionId={Sections.REVIEWS} ref={navigationRefs}>
            <DividerWrapper isMobileView={true} paddingTop="36px">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ProjectsReviews isMobileView={isMobileView} />
              </ErrorBoundary>
            </DividerWrapper>
          </SectionWrapper>
        </Box>
        <CompactBrief
          isMobileView={true}
          closeModal={closeModal}
          isOpen={isOpen}
          setShowSuccessNotification={setShowSuccessNotification}
          setShowErrorNotification={setShowErrorNotification}
          setBriefTimedOutMessage={setBriefTimedOutMessage}
          briefTimedOutMessage={briefTimedOutMessage}
        />
      </Box>
    );
  }

  return (
    <Box display="block" dataHook="profile-page" backgroundColor="white">
      {!isRenderedInModal ? (
        <div className={s.stickyHeader}>
          <Header
            onClick={headerOnClick}
            pageName="profile page"
            sessionId={sessionId}
          />
        </div>
      ) : (
        <div className={s.stickyHeader}></div>
      )}
      <Notification
        type="local"
        theme="success"
        show={showSuccessNotification}
        autoHideTimeout={8000}
        onClose={() => {
          setShowSuccessNotification(false);
        }}
        dataHook="brief-success-message"
      >
        <Notification.TextLabel>
          {t('brief-submit-success-message')}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Notification
        type="local"
        theme="error"
        show={showErrorNotification}
        autoHideTimeout={8000}
        onClose={() => {
          setShowErrorNotification(false);
        }}
      >
        <Notification.TextLabel>
          {t('brief-submit-error-message')}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Box>
        <ContactPanel
          isMobileView={false}
          onContactClicked={onContactClicked}
        />
        <Box direction="vertical" width="calc(100% - 302px)">
          <div>
            <NavBar isMobileView={false} navigationRefs={navigationRefs} />
            <Box paddingLeft="96px" paddingRight="96px" maxWidth="1154px">
              <div style={{ width: '100%' }}>
                <SectionWrapper
                  ref={navigationRefs}
                  sectionId={Sections.ABOUT}
                  max-width="770px"
                >
                  <About />
                  <Box paddingBottom="22px">
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <Services />
                    </ErrorBoundary>
                  </Box>
                </SectionWrapper>

                <SectionWrapper
                  sectionId={Sections.FEATURED_PROJECTS}
                  ref={navigationRefs}
                >
                  <DividerWrapper>
                    <FeaturedProjects isMobileView={false} />
                  </DividerWrapper>
                </SectionWrapper>
                <SectionWrapper
                  sectionId={Sections.SERVICES_AND_RATES}
                  ref={navigationRefs}
                >
                  <DividerWrapper>
                    <StartingRates />
                  </DividerWrapper>
                </SectionWrapper>
                <SectionWrapper
                  sectionId={Sections.REVIEWS}
                  ref={navigationRefs}
                >
                  <DividerWrapper>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <ProjectsReviews isMobileView={isMobileView} />
                    </ErrorBoundary>
                  </DividerWrapper>
                </SectionWrapper>
                <TraceableTraderData />
              </div>
            </Box>
          </div>
        </Box>
      </Box>
      <CompactBrief
        isMobileView={false}
        closeModal={closeModal}
        isOpen={isOpen}
        setShowSuccessNotification={setShowSuccessNotification}
        setShowErrorNotification={setShowErrorNotification}
        setBriefTimedOutMessage={setBriefTimedOutMessage}
        briefTimedOutMessage={briefTimedOutMessage}
      />
    </Box>
  );
};

export default Profile;
